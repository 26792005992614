import { useState, useEffect, useRef } from "react";

// @mui
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// material components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "components/Tables/DataTable";

// Layout components
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import Footer from "components/Footer";

// Local components
import CreateRiderDialog from "./components/CreateRiderDialog";
import UpdateRiderDialog from "./components/UpdateRiderDialog";
import ChangePasswordDialog from "./components/ChangePasswordDialog";
import DeleteRiderDialog from "./components/DeleteRiderDialog";

// Data
import ridersTableData from "pages/Riders/data/ridersTableData";

const Riders = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [clickedRiderID, setClickedRiderId] = useState(null);
  const [initialFetch, setInitialFetch] = useState(false);

  const updateRiderRef = useRef();
  const deleteRiderRef = useRef();
  const changePasswordRef = useRef();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const showMenu = (e, riderId) => {
    setClickedRiderId(riderId);
    setAnchorEl(e.currentTarget);
  };

  const handleSuccessCreated = (rider) => {
    addRider(rider);
  };

  const handleSuccessUpdated = (rider) => {
    modifyRider(rider.id, rider);
  };

  const handleSuccessDeleted = (riderID) => {
    deleteRider(riderID);
  };

  const handleEditRider = () => {
    updateRiderRef.current.openDialog(clickedRiderID);
    handleClose();
  };

  const handleChangePassword = () => {
    changePasswordRef.current.openDialog(clickedRiderID);
    handleClose();
  };

  const handleDeleteRider = () => {
    deleteRiderRef.current.openDialog(clickedRiderID);
    handleClose();
  };

  const { columns, rows, fetchRiders, addRider, modifyRider, deleteRider } =
    ridersTableData({
      onActionClicked: showMenu,
    });

  useEffect(() => {
    if (initialFetch) {
      fetchRiders().then(() => {
        setInitialFetch(false);
      });
    }
  }, [fetchRiders, initialFetch]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3} sx={{ minHeight: "calc(100vh - 150px)" }}>
        <Grid container spacing={6}>
          <Grid size={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="md"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Riders
                </MDTypography>
              </MDBox>
            </Card>
            <Card sx={{ marginTop: "10px" }}>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  canSearch={false}
                  canSort={false}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={true}
                  noEndBorder={false}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <CreateRiderDialog
        onSuccessCreated={(rider) => handleSuccessCreated(rider)}
      />
      <UpdateRiderDialog
        ref={updateRiderRef}
        onSuccessUpdated={(rider) => handleSuccessUpdated(rider)}
      />
      <ChangePasswordDialog ref={changePasswordRef} />
      <DeleteRiderDialog
        ref={deleteRiderRef}
        onSuccessDeleted={(riderID) => handleSuccessDeleted(riderID)}
      />
      <Menu
        id="rider-action-menu"
        aria-labelledby="rider-action-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem onClick={handleEditRider}>Edit</MenuItem>
        <MenuItem onClick={handleChangePassword}>Change Password</MenuItem>
        <Divider />
        <MenuItem onClick={handleDeleteRider}>Delete</MenuItem>
      </Menu>
      <Footer />
    </DashboardLayout>
  );
};

export default Riders;
