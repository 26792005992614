import { useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// HTTP Service
import HttpService from "services/http-service";

const CreateUser = ({ onSuccessCreated = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [userTypeId, setUserType] = useState(2);
  const [userStatus, setUserStatus] = useState(true);
  const [loadingButton, setLoadingButton] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    password: false,
    email: false,
    confirmPassword: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    password: "",
    email: "",
    confirmPassword: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleUserTypeChange = (event) => {
    setUserType(Number(event.target.value) || 1);
  };

  const handleUserStatusChange = (event) => {
    setUserStatus(event.target.checked);
  };

  const handleCloseAddUser = () => {
    setOpen(false);
  };

  const handleOpenAddUser = () => {
    setOpen(true);
  };

  const validateInputs = () => {
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let hasErrors = false;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        email: "Invalid email address",
      }));
      hasErrors = true;
    }

    if (inputs.name.trim().length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, name: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        name: "Invalid name. Must be at least 6 characters",
      }));
      hasErrors = true;
    }

    if (inputs.password.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, password: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        password: "Password must be at least 8 characters",
      }));
      hasErrors = true;
    }

    if (inputs.confirmPassword.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        confirmPassword: "Password must be at least 8 characters",
      }));
      hasErrors = true;
    }

    if (inputs.password !== inputs.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: true,
        confirmPassword: true,
      }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        password: "Passwords do not match",
      }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  const requestCreateUser = async () => {
    try {
      let postData = {
        name: inputs.name,
        email: inputs.email,
        password: inputs.password,
        user_type_id: userTypeId,
        status: userStatus ? 1 : 2,
      };

      const response = await HttpService.post("/kitchen/users", postData);

      if (response.status === 201) {
        setInputs({
          name: "",
          password: "",
          confirmPassword: "",
          email: "",
        });

        setErrors({
          name: false,
          password: false,
          email: false,
          confirmPassword: false,
        });

        setErrorMessages({
          name: "",
          password: "",
          email: "",
          confirmPassword: "",
        });

        handleCloseAddUser();
        onSuccessCreated();
      }

      setLoadingButton(false);
    } catch (e) {
      setLoadingButton(false);
    }
  };

  const handleCreateUserFormSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    setLoadingButton(true);
    requestCreateUser();
  };

  return (
    <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="Add User"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon sx={{ fontSize: "1.25rem" }} />}
        onClick={handleOpenAddUser}
      ></SpeedDial>
      <Dialog
        open={open}
        onClose={handleCloseAddUser}
        fullWidth={true}
        maxWidth="sm"
      >
        <MDBox component="form" onSubmit={handleCreateUserFormSubmit}>
          <DialogTitle>Create User</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} pt={1}>
              <Grid size={12}>
                <MDInput
                  name="name"
                  label="Name"
                  fullWidth
                  onChange={handleFieldChange}
                  value={inputs.name}
                  error={errors.name}
                  helperText={errorMessages.name}
                />
              </Grid>
              <Grid size={12}>
                <MDInput
                  name="password"
                  label="Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.password}
                  error={errors.password}
                  helperText={errorMessages.password}
                />
              </Grid>
              <Grid size={12}>
                <MDInput
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.confirmPassword}
                  error={errors.confirmPassword}
                  helperText={errorMessages.confirmPassword}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6}}>
                <MDInput
                  name="email"
                  label="Email address"
                  fullWidth
                  type="email"
                  onChange={handleFieldChange}
                  value={inputs.email}
                  error={errors.email}
                  helperText={errorMessages.email}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6}}>
                <MDInput
                  name="user_type_id"
                  label="User Type"
                  fullWidth
                  select
                  value={userTypeId}
                  onChange={handleUserTypeChange}
                >
                  <MenuItem value={2}>Administrator</MenuItem>
                  <MenuItem value={3}>Staff</MenuItem>
                </MDInput>
              </Grid>
              <Grid size={{ xs: 12, md: 6}}>
                <MDBox mx={1}>
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label="Active"
                    checked={userStatus}
                    onChange={handleUserStatusChange}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseAddUser}
              color="secondary"
              variant="text"
            >
              Close
            </MDButton>
            {loadingButton ? (
              <CircularProgress />
            ) : (
              <MDButton color="primary" type="submit">
                Create and save user
              </MDButton>
            )}
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
};

CreateUser.propTypes = {
  onSuccessCreated: PropTypes.func,
};

export default CreateUser;
