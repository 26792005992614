import { useState, forwardRef, useImperativeHandle } from "react";

import PropTypes from "prop-types";

// @mui components
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// HTTP Service
import HttpService from "services/http-service";

const UpdateRiderDialog = forwardRef(({ onSuccessUpdated = () => {} }, ref) => {
  const [riderId, setRiderId] = useState(null);
  const [riderStatus, setRiderStatus] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [open, setOpenDialog] = useState(false);

  const [inputs, setInputs] = useState({
    name: "",
    contactNumber: "",
    status: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    contactNumber: false,
    status: "",
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    contactNumber: "",
    status: "",
  });

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleStatusChange = (event) => {
    setRiderStatus(event.target.checked);
  };

  const handleCloseDialog = (_event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenDialog(false);
  };

  const openDialog = async (riderId) => {
    setRiderId(riderId);
    setLoadingScreen(true);

    try {
      const response = await HttpService.get("/kitchen/riders/" + riderId);

      setInputs({
        name: response.data.name,
        contactNumber: response.data.contact_number,
      });

      setErrors({
        name: false,
        contactNumber: false,
      });

      setErrorMessages({
        name: "",
        contactNumber: "",
      });

      if (response.data.status === 1) {
        setRiderStatus(true);
      } else {
        setRiderStatus(false);
      }

      setLoadingScreen(false);
      setOpenDialog(true);
    } catch (e) {
      console.log(e);
    }
  };

  // Expose the openEditDialog function via ref
  useImperativeHandle(ref, () => ({
    openDialog,
  }));

  const validateInputs = () => {
    let hasErrors = false;

    if (inputs.name.trim().length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, name: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        name: "Invalid name. Must be at least 6 characters",
      }));
      hasErrors = true;
    }

    if (inputs.contactNumber.trim().length < 10) {
      setErrors((prevErrors) => ({ ...prevErrors, contactNumber: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        contactNumber: "Invalid contact number",
      }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  const requestUpdateRider = async () => {
    try {
      let postData = {
        name: inputs.name,
        contact_number: inputs.contactNumber,
        status: riderStatus ? 1 : 0,
      };

      const response = await HttpService.post(
        "/kitchen/riders/" + riderId,
        postData,
      );

      if (response.status === 200) {
        handleCloseDialog();
        onSuccessUpdated(response.data.rider);
      }

      setLoadingButton(false);
    } catch (e) {
      if (e.data.error && e.data.error.details) {
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };

        e.data.error.details.forEach((detail) => {
          if (detail.field && detail.field in newErrors) {
            newErrors[detail.field] = true;
            newErrorMessages[detail.field] = detail.message;
          }
        });

        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
      }

      setLoadingButton(false);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (!validateInputs()) {
      return;
    }

    setLoadingButton(true);
    requestUpdateRider();
  };

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
        <Dialog
          open={open}
          onClose={handleCloseDialog}
          fullWidth={true}
          maxWidth="sm"
        >
          <MDBox component="form" onSubmit={handleFormSubmit}>
            <DialogTitle>Update Rider</DialogTitle>
            <DialogContent>
              <Grid container spacing={3} pt={1}>
                <Grid size={12}>
                  <MDInput
                    name="name"
                    label="Name"
                    fullWidth
                    onChange={handleFieldChange}
                    value={inputs.name}
                    error={errors.name}
                    helperText={errorMessages.name}
                  />
                </Grid>
                <Grid size={12}>
                  <MDInput
                    name="contactNumber"
                    label="Contact number"
                    fullWidth
                    type="text"
                    onChange={handleFieldChange}
                    value={inputs.contactNumber}
                    error={errors.contactNumber}
                    helperText={errorMessages.contactNumber}
                  />
                </Grid>
                <Grid size={12}>
                  <MDBox mx={1}>
                    <FormControlLabel
                      control={<Switch color="primary" />}
                      label="Active"
                      checked={riderStatus}
                      onChange={handleStatusChange}
                    />
                  </MDBox>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <MDButton
                onClick={handleCloseDialog}
                color="secondary"
                variant="text"
              >
                Close
              </MDButton>
              <MDButton disabled={loadingButton} type="submit" color="primary">
                {loadingButton && (
                  <CircularProgress
                    color="warning"
                    size={20}
                    sx={{ marginRight: "5px" }}
                  />
                )}
                Save changes
              </MDButton>
            </DialogActions>
          </MDBox>
        </Dialog>
      </MDBox>
    </>
  );
});

UpdateRiderDialog.propTypes = {
  onSuccessUpdated: PropTypes.func,
};

UpdateRiderDialog.displayName = "UpdateRiderDialog";

export default UpdateRiderDialog;
