import React, { forwardRef } from "react";

import PropTypes from "prop-types";

// DateJS
import dayjs from "dayjs";

import Grid from "@mui/material/Grid";
import { List, ListItem, ListItemText } from "@mui/material";

import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

const OrderDetailsPrintable = forwardRef(({ orderDetails }, ref) => {
  const spanLabelStyles = {
    position: "absolute",
    top: -12,
    left: 0,
    fontSize: "0.8rem",
    fontWeight: "100",
  };

  return (
    <div ref={ref} className="printable-content">
      <MDBox p={2} sx={{ display: { xs: "block", displayPrint: "block" } }}>
        <Grid container spacing={2}>
          <Grid size={12}>
            <MDTypography variant="h3" sx={{ textAlign: "center" }}>
              {orderDetails.external_order_id}
            </MDTypography>
          </Grid>
          <Grid size={12}>
            <MDTypography variant="h5">
              {orderDetails.customer_name}
            </MDTypography>
          </Grid>
          <Grid size={12}>
            <MDTypography variant="body2">
              {orderDetails.shipping_address}
            </MDTypography>
          </Grid>
          <Grid size={4}>
            <MDTypography variant="h4" sx={{ position: "relative" }}>
              {dayjs(orderDetails.delivery_date).format("MM/DD/YYYY")}
              <span style={spanLabelStyles}>Delivery Date</span>
            </MDTypography>
          </Grid>
          <Grid size={4}>
            <MDTypography variant="h4" sx={{ position: "relative" }}>
              {dayjs(`1970-01-01 ${orderDetails.dispatch_time}`).format(
                "hh:mmA",
              )}
              <span style={spanLabelStyles}>Dispatch Time</span>
            </MDTypography>
          </Grid>
          <Grid size={4}>
            <MDTypography variant="h4" sx={{ position: "relative" }}>
              {dayjs(`1970-01-01 ${orderDetails.delivery_eta}`).format(
                "hh:mmA",
              )}
              <span style={spanLabelStyles}>Delivery Time</span>
            </MDTypography>
          </Grid>
          <Grid size={12}>
            {orderDetails.notes ? (
              <MDTypography variant="body2" sx={{ position: "relative" }}>
                {orderDetails.notes}
                <span style={spanLabelStyles}>Notes</span>
              </MDTypography>
            ) : null}
          </Grid>
          <Grid size={12}>
            <List sx={{ width: "100%" }}>
              {orderDetails.order_line_items.map((orderLineItem) => (
                <React.Fragment key={`${orderLineItem.id}-key-fragment`}>
                  {orderLineItem.subitems.length === 0 &&
                  !orderLineItem.item_description
                    .toLowerCase()
                    .includes("upgrade") ? (
                    <ListItem disablePadding>
                      <ListItemText
                        primary={`${orderLineItem.item_quantity} x ${orderLineItem.item_description}`}
                      />
                    </ListItem>
                  ) : null}

                  {orderLineItem.subitems.length !== 0 &&
                    orderLineItem.subitems.map((subitem) => (
                      <ListItem key={subitem.id} disablePadding>
                        <ListItemText
                          primary={`${subitem.item_quantity} x ${subitem.item_description}`}
                        />
                      </ListItem>
                    ))}
                </React.Fragment>
              ))}
            </List>
          </Grid>
        </Grid>
      </MDBox>
    </div>
  );
});

OrderDetailsPrintable.propTypes = {
  orderDetails: PropTypes.shape({
    id: PropTypes.number.isRequired,
    external_order_id: PropTypes.number.isRequired,
    customer_name: PropTypes.string.isRequired,
    notes: PropTypes.string,
    delivery_date: PropTypes.string.isRequired,
    dispatch_time: PropTypes.string.isRequired,
    delivery_eta: PropTypes.string.isRequired,
    shipping_address: PropTypes.string.isRequired,
    order_line_items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        item_quantity: PropTypes.number.isRequired,
        item_description: PropTypes.string.isRequired,
        subitems: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            item_quantity: PropTypes.number.isRequired,
            item_description: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
  }).isRequired,
};

OrderDetailsPrintable.displayName = "OrderDetailsPrintable";

export default OrderDetailsPrintable;
