import { useState, useEffect, useRef } from "react";

// react-to-print
import { useReactToPrint } from "react-to-print";

// react-csv
import CsvDownloader from "react-csv-downloader";

// DateJS
import dayjs from "dayjs";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import Skeleton from "@mui/material/Skeleton";

// @mui-x
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

import Footer from "components/Footer";
import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import DataTable from "components/Tables/DataTable";

import itemsReportTableData from "pages/ItemsSummary/data/itemsReportTableData.js";
import PrintableItems from "pages/ItemsSummary/components/PrintableItems";

const ItemsSummary = () => {
  const { columns, rows, fetchData, orderLines } = itemsReportTableData();
  const [csvFilename, setCsvFilename] = useState("items-summary");
  const [loadingData, setLoadingData] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: componentRef,
  });

  const csvColumns = [
    {
      id: "item_quantity",
      displayName: "Quantity",
    },
    {
      id: "item_description",
      displayName: "Item",
    },
  ];

  const [deliveryDateFilter, setDeliveryDateFilter] = useState(dayjs());

  const submitHandler = (e) => {
    e.preventDefault();

    let params = {
      filters: {
        delivery_date: deliveryDateFilter.format("YYYY-MM-DD"),
      },
    };

    setLoadingData(true);
    fetchData(params).then(() => {
      setLoadingData(false);
    });
    setCsvFilename("items-summary-" + deliveryDateFilter.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    let params = {
      filters: {
        delivery_date: deliveryDateFilter.format("YYYY-MM-DD"),
      },
    };

    setLoadingData(true);
    fetchData(params).then(() => {
      setLoadingData(false);
    }); // Initially fetch all data
    setCsvFilename("items-summary-" + deliveryDateFilter.format("YYYY-MM-DD"));
  }, [deliveryDateFilter, fetchData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid size={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                mb={3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="md"
                coloredShadow="info"
              >
                <MDTypography variant="h4" color="white">
                  Items
                </MDTypography>
              </MDBox>
              <MDBox
                component="form"
                role="form"
                method="POST"
                onSubmit={submitHandler}
              >
                <Grid container spacing={2} mb={2} mx={1}>
                  <Grid>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        fullWidth
                        label="Delivery Date"
                        value={deliveryDateFilter}
                        onChange={(newValue) => setDeliveryDateFilter(newValue)}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid>
                    <MDButton color="primary" type="submit">
                      Filter Items
                    </MDButton>
                  </Grid>
                  {rows.length > 0 && (
                    <>
                      <Grid>
                        <MDButton
                          color="success"
                          onClick={handlePrint}
                          startIcon={<Icon>printer</Icon>}
                        >
                          Print
                        </MDButton>
                      </Grid>
                      <Grid>
                        <CsvDownloader
                          datas={orderLines}
                          columns={csvColumns}
                          filename={csvFilename}
                          extension=".csv"
                        >
                          <MDButton
                            color="info"
                            startIcon={<FileDownloadOutlinedIcon />}
                          >
                            Export
                          </MDButton>
                        </CsvDownloader>
                      </Grid>
                    </>
                  )}
                </Grid>
              </MDBox>
            </Card>
            <Card sx={{ marginTop: "10px" }}>
              <MDBox pt={3} mb={3}>
                {!loadingData ? (
                  <DataTable
                    table={{ columns, rows }}
                    canSearch={false}
                    canSort={false}
                    isSorted={false}
                    entriesPerPage={true}
                    showTotalEntries={true}
                    noEndBorder={false}
                  />
                ) : (
                  <MDBox
                    p={3}
                    pt={5}
                    mt={2}
                    textAlign="center"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                  >
                    <Grid container spacing={2}>
                      <Grid size={1}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid size={1}>
                        <MDBox>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                      <Grid size={12}>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                        <MDBox mb={3}>
                          <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                        </MDBox>
                      </Grid>
                    </Grid>
                  </MDBox>
                )}
              </MDBox>
              <MDBox sx={{ display: "none" }}>
                <PrintableItems rows={rows} ref={componentRef} />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default ItemsSummary;
