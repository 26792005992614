import { useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";

// HTTP Service
import HttpService from "services/http-service";

const CreateRiderDialog = ({ onSuccessCreated = () => {} }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [riderStatus, setRiderStatus] = useState(true);

  const [inputs, setInputs] = useState({
    name: "",
    password: "",
    confirmPassword: "",
    email: "",
    contactNumber: "",
  });

  const [errors, setErrors] = useState({
    name: false,
    password: false,
    email: false,
    confirmPassword: false,
    contactNumber: false,
  });

  const [errorMessages, setErrorMessages] = useState({
    name: "",
    password: "",
    email: "",
    confirmPassword: "",
    contactNumber: "",
  });

  const resetFields = () => {
    setInputs({
      name: "",
      password: "",
      confirmPassword: "",
      email: "",
      contactNumber: "",
    });

    setErrors({
      name: false,
      password: false,
      email: false,
      confirmPassword: false,
      contactNumber: false,
    });

    setErrorMessages({
      name: "",
      password: "",
      email: "",
      confirmPassword: "",
      contactNumber: "",
    });
  };

  const handleFieldChange = (e) => {
    const { name, value } = e.target;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));

    setErrorMessages((prevMessages) => ({
      ...prevMessages,
      [name]: "",
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };

  const handleCloseCreateRiderDialog = (_event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpenDialog(false);
  };

  const handleOpenCreateRiderDialog = () => {
    setOpenDialog(true);
  };

  const handleRiderStatusChange = (event) => {
    setRiderStatus(event.target.checked);
  };

  const validateInputs = () => {
    const mailFormat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    let hasErrors = false;

    if (
      inputs.email.trim().length === 0 ||
      !inputs.email.trim().match(mailFormat)
    ) {
      setErrors((prevErrors) => ({ ...prevErrors, email: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        email: "Invalid email address",
      }));
      hasErrors = true;
    }

    if (inputs.name.trim().length < 6) {
      setErrors((prevErrors) => ({ ...prevErrors, name: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        name: "Invalid name. Must be at least 6 characters",
      }));
      hasErrors = true;
    }

    if (inputs.password.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, password: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        password: "Password must be at least 8 characters",
      }));
      hasErrors = true;
    }

    if (inputs.confirmPassword.trim().length < 8) {
      setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        confirmPassword: "Password must be at least 8 characters",
      }));
      hasErrors = true;
    }

    if (inputs.password !== inputs.confirmPassword) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        password: true,
        confirmPassword: true,
      }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        password: "Passwords do not match",
      }));
      hasErrors = true;
    }

    if (inputs.contactNumber.trim().length < 10) {
      setErrors((prevErrors) => ({ ...prevErrors, contactNumber: true }));
      setErrorMessages((prevMessages) => ({
        ...prevMessages,
        contactNumber: "Invalid contact number",
      }));
      hasErrors = true;
    }

    return !hasErrors;
  };

  const requestCreateRider = async () => {
    try {
      let postData = {
        name: inputs.name,
        email: inputs.email,
        password: inputs.password,
        confirm_password: inputs.confirmPassword,
        contact_number: inputs.contactNumber,
        status: riderStatus ? 1 : 0,
      };

      const response = await HttpService.post("/kitchen/riders", postData);

      if (response.status === 201) {
        resetFields();
        handleCloseCreateRiderDialog();
        onSuccessCreated(response.data.rider);
      }

      setLoadingButton(false);
    } catch (e) {
      if (e.data.error && e.data.error.details) {
        const newErrors = { ...errors };
        const newErrorMessages = { ...errorMessages };

        e.data.error.details.forEach((detail) => {
          if (detail.field && detail.field in newErrors) {
            newErrors[detail.field] = true;
            newErrorMessages[detail.field] = detail.message;
          }
        });

        setErrors(newErrors);
        setErrorMessages(newErrorMessages);
      }

      setLoadingButton(false);
    }
  };

  const handleCreateRiderFormSubmit = (e) => {
    e.preventDefault();

    if (!validateInputs()) {
      return;
    }

    setLoadingButton(true);
    requestCreateRider();
  };

  return (
    <MDBox sx={{ transform: "translateZ(0px)", flexGrow: 1 }}>
      <SpeedDial
        ariaLabel="Add Rider"
        sx={{ position: "absolute", bottom: 16, right: 16 }}
        icon={<SpeedDialIcon sx={{ fontSize: "1.25rem" }} />}
        onClick={handleOpenCreateRiderDialog}
      ></SpeedDial>
      <Dialog
        open={openDialog}
        onClose={handleCloseCreateRiderDialog}
        fullWidth={true}
        maxWidth="sm"
      >
        <MDBox component="form" onSubmit={handleCreateRiderFormSubmit}>
          <DialogTitle>Create Rider</DialogTitle>
          <DialogContent>
            <Grid container spacing={3} pt={1}>
              <Grid size={12}>
                <MDInput
                  name="name"
                  label="Name"
                  fullWidth
                  onChange={handleFieldChange}
                  value={inputs.name}
                  error={errors.name}
                  helperText={errorMessages.name}
                />
              </Grid>
              <Grid size={12}>
                <MDInput
                  name="password"
                  label="Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.password}
                  error={errors.password}
                  helperText={errorMessages.password}
                />
              </Grid>
              <Grid size={12}>
                <MDInput
                  name="confirmPassword"
                  label="Confirm Password"
                  fullWidth
                  type="password"
                  onChange={handleFieldChange}
                  value={inputs.confirmPassword}
                  error={errors.confirmPassword}
                  helperText={errorMessages.confirmPassword}
                />
              </Grid>
              <Grid size={12}>
                <MDInput
                  name="email"
                  label="Email address"
                  fullWidth
                  type="email"
                  onChange={handleFieldChange}
                  value={inputs.email}
                  error={errors.email}
                  helperText={errorMessages.email}
                />
              </Grid>
              <Grid size={12}>
                <MDInput
                  name="contactNumber"
                  label="Contact number"
                  fullWidth
                  type="text"
                  onChange={handleFieldChange}
                  value={inputs.contactNumber}
                  error={errors.contactNumber}
                  helperText={errorMessages.contactNumber}
                />
              </Grid>
              <Grid size={12}>
                <MDBox mx={1}>
                  <FormControlLabel
                    control={<Switch color="primary" />}
                    label="Active"
                    checked={riderStatus}
                    onChange={handleRiderStatusChange}
                  />
                </MDBox>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <MDButton
              onClick={handleCloseCreateRiderDialog}
              color="secondary"
              variant="text"
            >
              Close
            </MDButton>
            <MDButton disabled={loadingButton} type="submit" color="primary">
              {loadingButton && (
                <CircularProgress
                  color="warning"
                  size={20}
                  sx={{ marginRight: "5px" }}
                />
              )}
              Create and save rider
            </MDButton>
          </DialogActions>
        </MDBox>
      </Dialog>
    </MDBox>
  );
};

CreateRiderDialog.propTypes = {
  onSuccessCreated: PropTypes.func,
};

export default CreateRiderDialog;
