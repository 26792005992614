import { useCallback, useEffect, useMemo, useState, useRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// DateJS
import dayjs from "dayjs";

// react-to-print
import { useReactToPrint } from "react-to-print";

// @mui material components
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import SpeedDial from "@mui/material/SpeedDial";
import Drawer from "@mui/material/Drawer";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDProgress from "components/MDProgress";
import AGDivider from "components/AGDivider";

import OrderDetailsDialog from "./components/OrderDetailsDialog";
import OrderStatus from "components/OrderStatus";
import OrdersPrintable from "./components/OrdersPrintable";

// HTTP Service
import HttpService from "services/http-service";

const StaffOrders = () => {
  const lsOrders = JSON.parse(localStorage.getItem("orders") || "{}");
  const [deliveryDateFilter, setDeliveryDateFilter] = useState(
    lsOrders.filters?.delivery_date
      ? dayjs(lsOrders.filters.delivery_date)
      : dayjs(),
  );

  const [openDrawer, setOpenDrawer] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [openOrderDetails, setOpenOrderDetails] = useState(false);
  const [orderDetails, setOrderDetails] = useState(null);
  const [isRequestLoading, setRequestLoading] = useState(false);
  const [checkedOrderLines, setCheckedOrderLines] = useState([]);
  const [isNoRecords, setIsNoRecords] = useState(false);
  const [loadingScreen, setLoadingScreen] = useState(false);
  const [printableRendered, setPrintableRendered] = useState(false);
  const [printableOrders, setPrintableOrders] = useState([]);

  const printableRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: printableRef,
  });

  const printOrders = async () => {
    let fetchDataParams = {
      filters: {
        delivery_date: deliveryDateFilter
          ? deliveryDateFilter.format("YYYY-MM-DD")
          : null,
      },
      order_lines: 1,
    };
    // set the loading backdrop true here.
    setLoadingScreen(true);
    try {
      const response = await HttpService.get(
        "/kitchen/staff/orders/printable",
        fetchDataParams,
      );

      setPrintableOrders(response.data.orders);
      const checkPrintableRendered = setInterval(() => {
        if (printableRendered) {
          clearInterval(checkPrintableRendered); // Stop checking when rendered
          handlePrint();
          setLoadingScreen(false);
        }
      }, 500);
    } catch (e) {
      console.log(e);
    }
  };

  const spanLabelStyles = {
    position: "absolute",
    top: -10,
    left: 0,
    fontSize: "0.8rem",
    fontWeight: "100",
  };

  const [orders, setOrders] = useState([]);
  const [overdueOrders, setOverdueOrders] = useState([]);
  const [preparingOrders, setPreparingOrders] = useState([]);
  const [paymentConfirmedOrders, setPaymentConfirmedOrders] = useState([]);
  const [packagingOrders, setPackagingOrders] = useState([]);
  const [waitingForRiderOrders, setWaitingForRiderOrders] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);

  const categorizeOrders = (orders) => {
    const currentDate = new Date();
    const categorized = {
      overdue: [],
      paymentConfirmed: [],
      preparing: [],
      packaging: [],
      waitingForRider: [],
      completed: [],
    };

    if (orders.length === 0) {
      setIsNoRecords(true);
    } else {
      setIsNoRecords(false);
    }

    orders.forEach((order) => {
      const deliveryDateTime = new Date(
        `${order.delivery_date} ${order.dispatch_time}`,
      );

      if (currentDate >= deliveryDateTime) {
        if ([3, 4, 5, 6].includes(order.status)) {
          categorized.overdue.push(order);
        }
      } else {
        if (order.status === 3) {
          categorized.paymentConfirmed.push(order);
        } else if (order.status === 4) {
          categorized.preparing.push(order);
        } else if (order.status === 5) {
          categorized.packaging.push(order);
        } else if (order.status === 6) {
          categorized.waitingForRider.push(order);
        }
      }

      if (order.status === 7) {
        categorized.completed.push(order);
      }
    });

    return categorized;
  };

  // Memoize categorized orders
  const categorizedOrders = useMemo(() => categorizeOrders(orders), [orders]);

  useEffect(() => {
    // Set the state for each category
    setOverdueOrders(categorizedOrders.overdue);
    setPaymentConfirmedOrders(categorizedOrders.paymentConfirmed);
    setPreparingOrders(categorizedOrders.preparing);
    setPackagingOrders(categorizedOrders.packaging);
    setWaitingForRiderOrders(categorizedOrders.waitingForRider);
    setCompletedOrders(categorizedOrders.completed);
  }, [categorizedOrders]);

  // Update the initialization of checkedOrderLines based on the completed values
  useEffect(() => {
    if (orderDetails && orderDetails.order_line_items) {
      const initialCheckedOrderLines = orderDetails.order_line_items.reduce(
        (accumulator, orderLineItem) => {
          if (orderLineItem.is_completed === true) {
            accumulator.push(orderLineItem.id);
          }

          if (orderLineItem.subitems.length > 0) {
            const subitemIds = orderLineItem.subitems
              .filter((subitem) => subitem.is_completed === true)
              .map((subitem) => subitem.id);

            accumulator.push(...subitemIds);
          }

          return accumulator;
        },
        [],
      );
      setCheckedOrderLines(initialCheckedOrderLines);
    }
  }, [orderDetails]);

  const handleOrderLineCheckboxChange = async (orderLineId) => {
    const updatedCheckedOrderLines = [...checkedOrderLines];
    const index = updatedCheckedOrderLines.indexOf(orderLineId);

    let isChecked = 0;
    if (index === -1) {
      setIsLoading(false);
      updatedCheckedOrderLines.push(orderLineId);
      isChecked = 1;
    } else {
      updatedCheckedOrderLines.splice(index, 1);
    }

    setCheckedOrderLines(updatedCheckedOrderLines);

    try {
      let postData = {
        is_completed: isChecked,
      };

      HttpService.put("/kitchen/order-lines/" + orderLineId, postData);
    } catch (e) {
      console.log(e);
    }
  };

  const handleCloseOrderDetails = () => {
    fetchOrders();
    setOpenOrderDetails(false);
  };

  const handleOrderClick = async (e, orderID) => {
    e.preventDefault();

    try {
      setRequestLoading(true);
      const response = await HttpService.get(
        "/kitchen/staff/orders/" + orderID,
      );
      if (response.status === 200) {
        setOrderDetails(response.data);
        setOpenOrderDetails(true);
      }
    } catch (e) {
      console.log(e);
      setRequestLoading(false);
      setOpenOrderDetails(false);
    }
    setRequestLoading(false);
  };

  const LoadingSkeleton = () => {
    const grids = Array.from({ length: 12 }, (_, index) => (
      <Grid key={index} size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 3 }}>
        <Card>
          <MDBox p={2}>
            <Grid container spacing={1}>
              <Grid size={6}>
                <MDTypography variant="h2">
                  <Skeleton />
                </MDTypography>
              </Grid>
              <Grid size={6}>
                <MDTypography variant="h2">
                  <Skeleton />
                </MDTypography>
              </Grid>
              <Grid size={12}>
                <MDTypography variant="body">
                  <Skeleton />
                </MDTypography>
                <MDTypography variant="body">
                  <Skeleton />
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>
        </Card>
      </Grid>
    ));

    return (
      <Grid container spacing={2}>
        {grids}
      </Grid>
    );
  };

  const OrderProgress = ({ completed, total }) => {
    const progress = Math.floor((completed / total) * 100);

    let color;

    if (progress === 100) {
      color = "success"; // Green when progress is 100%
    } else if (progress < 50) {
      color = "error"; // Red when progress is 0%
    } else {
      // Gradient color based on progress
      color = "info";
    }

    return (
      <MDProgress
        value={progress}
        color={color}
        variant="gradient"
        label={true}
      />
    );
  };

  OrderProgress.propTypes = {
    completed: PropTypes.number,
    total: PropTypes.number,
  };

  const handleClickFilter = (e) => {
    e.preventDefault();
    toggleDrawer(false);
    fetchOrders();
  };

  const toggleDrawer = (open) => {
    setOpenDrawer(open);
  };

  const fetchOrders = useCallback(async () => {
    let params = {
      filters: {
        delivery_date: deliveryDateFilter.format("YYYY-MM-DD"),
      },
    };
    setIsLoading(true);

    try {
      const response = await HttpService.get("/kitchen/staff/orders", params);
      setOrders(response.data.orders);

      localStorage.setItem(
        "orders",
        JSON.stringify({
          filters: {
            delivery_date: deliveryDateFilter,
          },
        }),
      );
      setIsLoading(false);
    } catch (e) {
      console.log(e);
    }
  }, [deliveryDateFilter]);

  useEffect(() => {
    if (isInitialLoading) {
      fetchOrders();
      setIsInitialLoading(false);
    }
  }, [fetchOrders, isInitialLoading]);

  const OrderGrid = ({ orders, title, onClickHandler, spanLabelStyles }) => (
    <>
      <AGDivider variant="middle">{title}</AGDivider>
      <Grid container spacing={4}>
        {orders.map((order) => (
          <Grid key={order.id} size={{ size: 12, sm: 6, md: 4, lg: 4, xl: 3 }}>
            <Card>
              <MDBox
                pt={3}
                px={2}
                pb={2}
                onClick={(e) => onClickHandler(e, order.id)}
                sx={{ cursor: "pointer" }}
              >
                <MDBox sx={{ position: "absolute", top: -16, right: 10 }}>
                  <OrderStatus status={order.status} />
                </MDBox>
                <Grid container spacing={1} mt={1}>
                  <Grid size={6}>
                    <MDTypography variant="h3" sx={{ position: "relative" }}>
                      {order.external_order_id}{" "}
                      <span style={spanLabelStyles}>Invoice ID</span>
                    </MDTypography>
                  </Grid>
                  <Grid size={6}>
                    <MDTypography variant="h3" sx={{ position: "relative" }}>
                      {dayjs(`1970-01-01 ${order.dispatch_time}`).format(
                        "hh:mmA",
                      )}{" "}
                      <span style={spanLabelStyles}>Dispatch Time</span>
                    </MDTypography>
                  </Grid>
                  <Grid size={12}>
                    <OrderProgress
                      completed={order.items_completed_count}
                      total={order.items_count}
                    />
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );

  OrderGrid.propTypes = {
    orders: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    onClickHandler: PropTypes.func.isRequired,
    spanLabelStyles: PropTypes.object.isRequired,
  };

  return (
    <MDBox width="100%">
      {isLoading === false ? (
        isNoRecords === false ? (
          <>
            {overdueOrders && overdueOrders.length > 0 && (
              <OrderGrid
                orders={overdueOrders}
                title="Missed Dispatch"
                onClickHandler={handleOrderClick}
                spanLabelStyles={spanLabelStyles}
              />
            )}
            {paymentConfirmedOrders && paymentConfirmedOrders.length > 0 && (
              <OrderGrid
                orders={paymentConfirmedOrders}
                title="Payment Confirmed"
                onClickHandler={handleOrderClick}
                spanLabelStyles={spanLabelStyles}
              />
            )}
            {preparingOrders && preparingOrders.length > 0 && (
              <OrderGrid
                orders={preparingOrders}
                title="Preparing Orders"
                onClickHandler={handleOrderClick}
                spanLabelStyles={spanLabelStyles}
              />
            )}
            {packagingOrders && packagingOrders.length > 0 && (
              <OrderGrid
                orders={packagingOrders}
                title="For Packaging"
                onClickHandler={handleOrderClick}
                spanLabelStyles={spanLabelStyles}
              />
            )}
            {waitingForRiderOrders && waitingForRiderOrders.length > 0 && (
              <OrderGrid
                orders={waitingForRiderOrders}
                title="Waiting for Rider"
                onClickHandler={handleOrderClick}
                spanLabelStyles={spanLabelStyles}
              />
            )}
            {completedOrders && completedOrders.length > 0 && (
              <OrderGrid
                orders={completedOrders}
                title="Completed"
                onClickHandler={handleOrderClick}
                spanLabelStyles={spanLabelStyles}
              />
            )}
          </>
        ) : (
          <MDBox textAlign="center">
            <MDTypography>There are no orders available.</MDTypography>
          </MDBox>
        )
      ) : (
        <LoadingSkeleton />
      )}
      <OrderDetailsDialog
        open={openOrderDetails}
        handleCloseOrderDetails={handleCloseOrderDetails}
        orderDetails={orderDetails}
        checkedOrderLines={checkedOrderLines}
        handleOrderLineCheckboxChange={handleOrderLineCheckboxChange}
      />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loadingScreen}
      >
        <CircularProgress color="inherit" />
        <MDTypography variant="h3" color="white" ml={3}>
          Please wait while preparing print preview.
        </MDTypography>
      </Backdrop>

      <MDBox sx={{ display: "none" }}>
        <OrdersPrintable
          ref={printableRef}
          orders={printableOrders}
          onRender={setPrintableRendered}
        />
      </MDBox>
      <SpeedDial
        ariaLabel="Filters"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
        icon={
          <Icon fontSize="small" color="inherit">
            settings
          </Icon>
        }
        onClick={() => toggleDrawer(true)}
      />
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          sx: {
            width: 360,
            "& .MuiDrawer-paper": {
              width: 360,
            },
          },
        }}
      >
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          pt={4}
          pb={0.5}
          px={3}
        >
          <MDBox>
            <MDTypography variant="h5">Filters</MDTypography>
          </MDBox>

          <Icon
            sx={({ typography: { size } }) => ({
              fontSize: `${size.lg} !important`,
              stroke: "currentColor",
              strokeWidth: "2px",
              cursor: "pointer",
              transform: "translateY(5px)",
            })}
            onClick={() => toggleDrawer(false)}
          >
            close
          </Icon>
        </MDBox>

        <Divider />

        <MDBox pt={2} px={3}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              fullWidth
              label="Delivery Date"
              value={deliveryDateFilter}
              onChange={(newValue) => setDeliveryDateFilter(newValue)}
            />
          </LocalizationProvider>
        </MDBox>
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={1}
          px={3}
          lineHeight={1}
        >
          <MDButton
            fullWidth
            color="primary"
            onClick={(e) => handleClickFilter(e)}
          >
            Filter Orders
          </MDButton>
        </MDBox>
        {orders.length !== 0 && (
          <MDBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mt={1}
            px={3}
            lineHeight={1}
          >
            <MDButton fullWidth color="secondary" onClick={() => printOrders()}>
              Print Orders
            </MDButton>
          </MDBox>
        )}
      </Drawer>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isRequestLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </MDBox>
  );
};

export default StaffOrders;
